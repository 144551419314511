import React, { useState } from "react";
import Image from "../../assets/webImage/revel.jpg";
import Image2 from "../../assets/webImage/nipp.jpg";
import Image3 from "../../assets/webImage/webImage01.jpg";
import Image4 from "../../assets/webImage/stillrise.jpg";
import Image5 from "../../assets/webImage/adistaffing.png";
import Image6 from "../../assets/webImage/talentupulseconsultant.png";
///Mobile Image
import OyaRider from "../../assets/MobileImage/oyarider.jpg";
import irafinancial from "../../assets/MobileImage/irafinancial.jpg";
import nationoak from "../../assets/MobileImage/nationaloak.jpg";
import Oya from "../../assets/MobileImage/Oye.jpg";
import woolomi from "../../assets/MobileImage/woolomi.jpg";
// import mobileImage5 from "../../../assets/MobileImage/macro.jpg";
import Macro from "../../assets/MobileImage/macro02.jpg";
import macro from "../../assets/MobileImage/macro3.jpg";

import wellConsult from "../../assets/MobileImage/wellconsult.jpg";
import golfRoster from "../../assets/MobileImage/golfroaster.jpg";
//appIcon
import googleplay from "../../assets/app-icon/googleplay.webp";
import appstore from "../../assets/app-icon/appstore.webp";
import { Dialog } from "@mui/material";
import ERPPortfolio from "../Portfolio/ERPPortfolio";
import ArchdPortfolio from "../Portfolio/ArchdPortfolio";
const TempPortfolio = () => {
  const [ERPTab, setERPTab] = useState(0);
  const [open, setOpen] = useState(false);
  const [tab, setTab] = useState(0);
  const [selectedItemIndex, setSelectedItemIndex] = useState(null);
  const handleOpen = (index) => {
    setOpen(true);
    setSelectedItemIndex(index);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const imageUrls = [
    {
      image: Image6,
      title: "Alentpulseconsultant",
      url: "https://talentpulseconsultant.in/",
    },
    {
      image: Image5,
      title: "Adistaffing",
      url: "https://adistaffing.com",
    },
    {
      image: Image2,
      title: "Nipp",
      url: "https://ournipp.com/",
    },
    {
      image: Image3,
      title: "FinMedia",
      url: "https://finmedialtd.com/",
    },
    {
      image: Image4,
      title: "Still Rise",
      url: "https://stillrisefarms.com",
    },
    // {
    //   image: Image,
    //   title: "Reval",
    //   url: "https://ournipp.com/",
    // },
    {
      image: Image,
      title: "Reval",
      url: "https://withrevel.io/",
    },
  ];
  const mobileImage = [
    {
      id: "1",
      image: OyaRider,
      title: " OYA Rider", //done
      description:
        "OYA is a delivery app that enables consumers to receive their choice.food at their doorstep whenever they desire. As a Foodmine Delivery Partner, you can choose your flexible work schedule, gain rewards. We have an easy-to-use application for the delivery partner, so they don't have to spend their valuable time and get a hassle-free experience.We have several features added that assist you in better planning every delivery: you can check how long it will take to reach the location with the optimal path and also easily verify your income for every order and report any problems if faced.",
      appStoreImage: appstore,
      playstoreImage: googleplay,
      appstoreLink: "https://apps.apple.com/bs/app/oya-rider/id1670104863",
      playstoreLink:
        "https://play.google.com/store/apps/details?id=com.oya.rider&hl=en&gl=US",
    },
    {
      id: "2",
      image: Oya,
      title: "OYA Grocery and Food delivery", //done
      description:
        "Find your favourite local restaurants and famous restaurant brands all under one roof. You may order food from various restaurants using our app and deliver it to your house in minutes. Another benefit is that you can track your delivery with live tracking. Our system is set up to confirm, prepare, and deliver your order as soon as feasible.Browse restaurant menus, ratings, reviews, images, contact information, map directions, and all the additional information you need for your next meal in one place—rate restaurants to help other foods as well. ",
      appStoreImage: appstore,
      playstoreImage: googleplay,
      appstoreLink:
        "https://apps.apple.com/in/app/oya-cinema-food-and-grocery/id1668429133",
      playstoreLink:
        "https://play.google.com/store/apps/details?id=com.oya.delivery&hl=en_US",
    },
    {
      id: "3",
      image: Macro,
      title: "Makro PRO", //done
      description:
        "With Makro PRO, you can say goodbye to the inconvenience of driving to the store and welcome a more comfortable shopping experience. Our cutting-edge app and online platform allow you to order all your necessary supplies from the convenience of your home or workplace, saving you both time and money at your fingertips.Makro PRO brings you access to the entire Makro warehouseMakro PRO brings you access to the entire Makro warehouseBrowse our large selection of high-quality items ranging from groceries to gadgets. There will be no more negotiating packed aisles or standing in long checkout lines. With just a few taps or clicks, you can search for, select, and add products to your basket. Easy ordering and Quick Delivery!",
      appStoreImage: appstore,
      playstoreImage: googleplay,
      appstoreLink: "https://apps.apple.com/us/app/makro-pro/id1570380497",
      playstoreLink:
        "https://play.google.com/store/apps/details?id=com.makromangoapp.production&hl=en&gl=US",
    },
    {
      id: "4",
      image: woolomi,
      title: " Wolomi", //done
      description:
        "Wolomi is the first online pregnancy community for women of color, led by a maternal health nurse of color. Membership to Wolomi gives you access to maternal health experts, inspiring events, and a community of amazing women like you",
      appStoreImage: appstore,

      playstoreImage: googleplay,
      appstoreLink:
        "https://apps.apple.com/us/app/wolomi-a-pregnancy-companion/id1512550645",
      playstoreLink:
        "https://play.google.com/store/apps/details?id=com.wolomi&hl=en_IN&gl=US",
    },
    {
      id: "5",
      image: golfRoster,
      title: "Golf Roster", //done
      description:
        "Play your best golf by connecting with like minded players before you even get to the course! Use GolfRoster to find your ideal golf buddies and create a great network.No more getting stuck spending 4 hours with a golfer you dread playing with. Use GolfRoster to find compatible playing partners by handicap, location, favorite course, age, tee choice and many more criteria in advance of your tee time. No more surprises on the first tee!,Whether a member of a private course, a frequent player at a public course or playing one time at a course, use GolfRoster to find a compatible player to have a great round of golf every time.",
      appStoreImage: appstore,
      appstoreLink: "https://apps.apple.com/us/app/golf-roster/id1494635455",
      playstoreImage: googleplay,
      playstoreLink: "",
    },
    {
      id: "6",
      image: nationoak,
      title: "NationalOak Distributors", //done
      description:
        "Browse, search and buy straight from your IOS device with the National Oak app. Developed to give our customers easy access to product details and stock information while on the go, the NOD App is great for Jobber business owners and sales teams who need to special order items for a customer. For larger orders, the app cart syncs with the cart on your desktop so that you may add items throughout the day then review them later to ensure you meet your freight prepaid limit. Key features include:",
      appStoreImage: appstore,
      playstoreImage: googleplay,
      appstoreLink:
        "https://apps.apple.com/us/app/national-oak-distributors/id1582306941?platform=iphone",
      playstoreLink:
        "https://play.google.com/store/apps/details?id=com.rave.nationaloak&hl=hi",
    },
    {
      id: "7",
      image: wellConsult,
      title: "Wealth Concert",
      description:
        "The Wealth Concert app is a tool that helps families and friends to self-collaborate by funding one another in the purchase of goods and services. It provides users with the functionalities that make fund distribution systematic and transparent and fosters fund to be shared with all members of the group in a rotational manner. At a predetermined cadence (weekly, biweekly or monthly), funds are directed to each member of the group by the rest members until all members are funded in the to purchase of their choices without incurring interest rates from banks or taking longer time to payback. less",
      appStoreImage: appstore,
      playstoreImage: googleplay,
      appstoreLink: "https://apps.apple.com/us/app/wealth-concert/id1576513504",
      playstoreLink:
        "https://play.google.com/store/apps/details?id=com.noblefolks.wealthconcertandroid&hl=en&gl=US",
    },
    {
      id: "8",
      image: irafinancial,
      title: " IRA Financial ", //done
      description:
        "IRA Financial, leading provider of self-directed retirement products announces the launch of its new app for iOS mobile phones. This addition to IRA Financial’s technology suite illustrates the company’s initiative to provide clients with the best user experience for both initial establishment and on-going maintenance of self-directed retirement accounts.",
      appStoreImage: appstore,
      playstoreImage: googleplay,
      appstoreLink: "https://apps.apple.com/us/app/ira-financial/id1442477372",
      playstoreLink:
        "https://play.google.com/store/apps/details?id=com.irafinancial&hl=en_US&gl=US",
    },
    {
      id: "9",
      image: macro,
      title: "Sligo Plant Hire & Haulage Ltd", //done
      description:
        "Sligo Plant Hire is a leading provider of comprehensive construction and groundworks solutions, serving clients in Sligo and the surrounding regions. With a strong reputation for excellence and reliability, we have been serving the construction industry for several years, delivering exceptional results and exceeding customer expectations.",
      appStoreImage: appstore,
      playstoreImage: googleplay,
      appstoreLink:
        "https://apps.apple.com/us/app/sligo-plant-hire-haulage-ltd/id6466501075",
      playstoreLink: "",
    },
  ];

  return (
    <div
      className="container-fluid py-sm-5 py-2 wow fadeInUp"
      data-aos="fade-up"
    >
      <div className="container pt-sm-5 py-4">
        <div
          className="section-title text-center position-relative pb-3 mb-5 mx-auto"
          style={{ maxWidth: "600px" }}
        >
          <h5 className="fw-bold text-primary text-uppercase">Our Portfolio</h5>
          <h1 className="mb-0">
            IT expert. Web, software, mobile development Let's innovate!
          </h1>
        </div>
        <div className="">
          <div className="d-flex justify-content-between align-items-center py-3 d-sm-none">
            <h6
              onClick={() => setTab(0)}
              className={`${
                tab === 0 ? "textColor" : ""
              } text-center fw-bold text-capitalize`}
              style={{ cursor: "pointer" }}
            >
              Mobile Application
            </h6>
            <h6
              onClick={() => setTab(1)}
              className={`${
                tab === 1 ? "textColor" : ""
              } text-center fw-bold  text-capitalize`}
              style={{ cursor: "pointer" }}
            >
              Web Development
            </h6>
            <h6
              onClick={() => setTab(2)}
              className={`${
                tab === 2 ? "textColor" : ""
              } text-center fw-bold  text-capitalize`}
              style={{ cursor: "pointer" }}
            >
              Software Products
            </h6>
          </div>
          <div className="d-none d-sm-block">
            <div className="d-flex justify-content-between align-items-center py-3 ">
              <h5
                onClick={() => setTab(0)}
                className={`${
                  tab === 0 ? "textColor" : ""
                } text-center fw-bold text-uppercase`}
                style={{ cursor: "pointer" }}
              >
                Mobile Application
              </h5>
              <h5
                onClick={() => setTab(1)}
                className={`${
                  tab === 1 ? "textColor" : ""
                } text-center fw-bold  text-uppercase`}
                style={{ cursor: "pointer" }}
              >
                Web Development
              </h5>
              <h5
                onClick={() => setTab(2)}
                className={`${
                  tab === 2 ? "textColor" : ""
                } text-center fw-bold  text-uppercase`}
                style={{ cursor: "pointer" }}
              >
                Software Products
              </h5>
            </div>
          </div>
          <div>
            {tab === 0 ? (
              <div className="row" style={{ cursor: "pointer" }}>
                {mobileImage.map((imageUrl, index) => (
                  <div className="col-sm-4" key={index}>
                    <div
                      className="mt-4 boxShadow"
                      onClick={() => handleOpen(index)}
                      style={{
                        // backgroundColor: "#F0F8FA",
                        boxShadow:
                          "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                      }}
                    >
                      <img
                        src={imageUrl.image}
                        className=""
                        alt=""
                        style={{
                          height: "200px",
                          width: "100%",
                          objectFit: "contain",
                          padding: "10px",
                        }}
                      />
                      <h6 className="bg-light text-primary text-center text-white py-1 fw-bold">
                        {imageUrl?.title}
                      </h6>
                    </div>
                  </div>
                ))}
              </div>
            ) : tab === 1 ? (
              <div className="row">
                {imageUrls?.map((imageUrl, index) => (
                  <div className="col-sm-4" key={index}>
                    <a href={imageUrl?.url} target="_blank" rel="noreferrer">
                      <div
                        className="mt-2"
                        onClick={() => {}}
                        style={{
                          // backgroundColor: "#F0F8FA",
                          boxShadow:
                            "rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px",
                        }}
                      >
                        <img
                          src={imageUrl?.image}
                          className=""
                          alt={`Card ${index}`}
                          style={{
                            height: "230px",
                            width: "100%",
                            objectFit: "contain",
                            padding: "10px",
                          }}
                        />
                        <h6 className="bg-light text-primary text-center text-white py-1 fw-bold">
                          {imageUrl?.title}
                        </h6>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            ) : tab === 2 ? (
              <div className="row">
                <div className="d-flex gap-2 mt-2">
                  <h6
                    onClick={() => setERPTab(0)}
                    className={` ${
                      ERPTab === 0
                        ? "bg-primary text-white rounded-pill px-3 py-1 text-center"
                        : "border px-3 rounded-pill text-primary py-1 border-primary text-center"
                    } `}
                    style={{ cursor: "pointer" }}
                  >
                    Julie Tailor
                  </h6>
                  <h6
                    onClick={() => setERPTab(1)}
                    className={` ${
                      ERPTab === 1
                        ? "bg-primary text-white rounded-pill px-3 py-1"
                        : "border px-3 rounded-pill text-primary py-1 border-primary"
                    }`}
                    style={{ cursor: "pointer" }}
                  >
                    Archd
                  </h6>
                </div>
                {ERPTab === 0 && (
                  <div>
                    {" "}
                    <ERPPortfolio />
                  </div>
                )}
                {ERPTab === 1 && (
                  <div>
                    <ArchdPortfolio />
                  </div>
                )}
              </div>
            ) : (
              tab
            )}
          </div>
        </div>
      </div>
      <Dialog
        open={open}
        onClose={handleClose}
        // fullWidth
        sx={{
          "& .MuiDialog-container": {
            "& .MuiPaper-root": {
              width: "100%",
              maxWidth: "1000px", // Set your width here
            },
          },
        }}
      >
        <div style={{ padding: "20px" }}>
          {/* Conditionally render content based on the selected tab */}
          {selectedItemIndex !== null && (
            <React.Fragment>
              <h2
                className="text-center textFamily text-primary"
                style={{ fontSize: "35px" }}
              >
                {mobileImage[selectedItemIndex].title}
              </h2>
              <div>
                <img
                  src={mobileImage[selectedItemIndex].image}
                  alt=""
                  style={{ width: "100%" }}
                  className=""
                  // style={{ width: "900px" }}
                />
              </div>
              <p className="text-start pt-2 px-2 textFamily">
                {mobileImage[selectedItemIndex].description}
              </p>
              <div className="d-sm-flex justify-content-center gap-sm-4">
                <div>
                  {mobileImage[selectedItemIndex].appstoreLink && (
                    <div className="d-flex justify-content-center">
                      <a
                        href={mobileImage[selectedItemIndex].appstoreLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={mobileImage[selectedItemIndex].appStoreImage}
                          alt="App Store"
                          style={{ width: "150px" }}
                        />
                      </a>
                    </div>
                  )}
                </div>
                <div>
                  {mobileImage[selectedItemIndex].playstoreLink && (
                    <div className="d-flex justify-content-center mt-2 mt-sm-0">
                      <a
                        href={mobileImage[selectedItemIndex].playstoreLink}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img
                          src={mobileImage[selectedItemIndex].playstoreImage}
                          alt="Play Store"
                          style={{ width: "150px" }}
                        />
                      </a>
                    </div>
                  )}
                </div>
              </div>
            </React.Fragment>
          )}
        </div>
      </Dialog>
    </div>
  );
};

export default TempPortfolio;
